import * as _ from 'lodash'
import { FormPlugin } from '../../../constants/plugins'
import { ComponentConnection, Plugin } from '../../../constants/api-types'

export const getActivePlugin = (plugins: FormPlugin[]): FormPlugin => {
  return _.first(plugins) || FormPlugin.FORM_BUILDER
}

export const getPlugins = (componentConnection: ComponentConnection): Plugin[] =>
  _.get(componentConnection, 'config.plugins', [])

export const convertPluginsToFormsPlugins = (plugins: Plugin[] = []): FormPlugin[] => _.map(plugins, 'id')

export const findPlugin = (plugins: Plugin[], pluginId: FormPlugin) => _.find(plugins, plugin => plugin.id === pluginId)

export const isNativeForm = (plugins: Plugin[]) => _.size(plugins) === 0 || (_.size(plugins) === 1 && !!findPlugin(plugins, FormPlugin.FORM_BUILDER))

export const updatePlugin = (plugins: Plugin[], newPlugin: Plugin) => {
  const updatedPlugins = _.filter(plugins, plugin => plugin.id !== newPlugin.id)
  return [...updatedPlugins, newPlugin]
}

export const removePlugin = (plugins: Plugin[], pluginId: FormPlugin) => {
  return _.filter(plugins, plugin => plugin.id !== pluginId)
}

import { FormsFieldPreset } from '../../../../../../constants/field-types'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../../../../../constants/component-types'
import { iconNames } from '../../icons/types'
import { FormPlugin } from '../../../../../../constants/plugins'
import { FieldRenderConfigType } from '../../constants'
import { IGeneralField } from '../../general-field'

export class GeneralSignature extends IGeneralField {
  public get customFields() {
    return []
  }

  protected get icon() {
    return iconNames.signature
  }

  protected get fieldType() {
    return FormsFieldPreset.GENERAL_SIGNATURE
  }

  public get collectionFieldType() {
    return FieldCollectionType.IMAGE
  }

  protected get componentType() {
    return COMPONENT_TYPES.SIGNATURE_INPUT
  }

  protected get role() {
    return FIELDS.ROLE_FIELD_SIGNATURE
  }

  protected get data(): any {
    return {
      titleText: this.translate(`preset.signatureTitleText`),
      clearButtonText: this.translate(`preset.signatureClearButtonText`),
    }
  }

  protected get props() {
    return {
      required: false,
    }
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        crmSync: FieldRenderConfigType.REMOVE,
      }
    }
  }

  public get base() {
    return GeneralSignature.prototype
  }
}

import { FormsFieldPreset } from '../../../../../../constants/field-types'
import { FIELDS } from '../../../../../../constants/roles'
import { GeneralSingleCheckbox } from '../../general-fields/definitions/general-single-checkbox'
import { FieldRenderConfigType } from '../../constants'
import { FormPlugin } from '../../../../../../constants/plugins'

export class AgreeTerms extends GeneralSingleCheckbox {
  protected get fieldType() {
    return FormsFieldPreset.AGREE_TERMS
  }

  protected get role() {
    return FIELDS.ROLE_FIELD_AGREE_TERMS
  }

  protected get data(): any {
    return {
      label: this.translate(`preset.${this.fieldType}Label`),
    }
  }

  protected get props() {
    return {
      required: true,
    }
  }

  protected get layout() {
    return {
      width: 290,
    }
  }

  public get allowOnlyOnce() {
    return true
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        required: FieldRenderConfigType.DISABLED,
        internalName: FieldRenderConfigType.DISABLED,
        checkedByDefault: FieldRenderConfigType.DISABLED,
      }
    }
  }
}

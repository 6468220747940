import { FormsFieldPreset, FieldPreset  } from '../../../../../../constants/field-types'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import { DEFAULT_PLACEHOLDER } from '../constants'
import { COMPONENT_TYPES } from '../../../../../../constants/component-types'
import { CUSTOM_FIELD } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'
import { FormPlugin } from '../../../../../../constants/plugins'
import { IGeneralField } from '../../general-field'

export class GeneralTextBox extends IGeneralField {
  protected get fieldType(): FieldPreset {
    return FormsFieldPreset.GENERAL_TEXT_BOX
  }

  protected get icon() {
    return iconNames.paragraph
  }

  public get collectionFieldType() {
    return FieldCollectionType.TEXT
  }

  protected get componentType() {
    return COMPONENT_TYPES.TEXT_AREA_INPUT
  }

  protected get role() {
    return FIELDS.ROLE_FIELD_TEXT_AREA
  }

  public get crmType() {
    return CUSTOM_FIELD
  }

  protected get data(): any {
    return {
      placeholder: this.translate(DEFAULT_PLACEHOLDER),
    }
  }

  protected get pluginsExtraData() {
    return {
      [FormPlugin.REGISTRATION_FORM]: {
        layout: {
          width: 240
        }
      }
    }
  }

  public get base() {
    return GeneralTextBox.prototype
  }
}

import { FormsFieldPreset } from '../../../../../../constants/field-types'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../../../../../constants/component-types'
import { iconNames } from '../../icons/types'
import { FormPlugin } from '../../../../../../constants/plugins'
import { IGeneralField } from '../../general-field'

export const makeGeneralRadioGroupOption = () => ({
  type: 'RadioButton',
  metaData: {
    isPreset: false,
    schemaVersion: '1.0',
    isHidden: false,
  },
  value: '',
  label: '',
})

export class GeneralRadioGroup extends IGeneralField {
  protected get icon() {
    return iconNames.radioButton
  }

  public get collectionFieldType() {
    return FieldCollectionType.TEXT
  }

  protected get componentType() {
    return COMPONENT_TYPES.RADIO_GROUP
  }

  protected get role() {
    return FIELDS.ROLE_FIELD_RADIO_LIST
  }

  protected get data() {
    return {
      label: this.translate(`preset.${this.fieldType}Label`),
      value: this.translate(`preset.${this.fieldType}FirstOptionsValue`),
      defaultValue: this.translate(`preset.${this.fieldType}FirstOptionsValue`),
      options: [
        {
          ...makeGeneralRadioGroupOption(),
          value: this.translate(`preset.${this.fieldType}FirstOptionsValue`),
          label: this.translate(`preset.${this.fieldType}FirstOptionsLabel`),
        },
        {
          ...makeGeneralRadioGroupOption(),
          value: this.translate(`preset.${this.fieldType}SecondOptionsValue`),
          label: this.translate(`preset.${this.fieldType}SecondOptionsLabel`),
        },
        {
          ...makeGeneralRadioGroupOption(),
          value: this.translate(`preset.${this.fieldType}ThirdOptionsValue`),
          label: this.translate(`preset.${this.fieldType}ThirdOptionsLabel`),
        },
      ],
    }
  }

  protected get fieldType() {
    return FormsFieldPreset.GENERAL_RADIO_BUTTON
  }

  protected get pluginsExtraData() {
    return {
      [FormPlugin.REGISTRATION_FORM]: {
        layout: {
          width: 240
        }
      }
    }
  }

  public get base() {
    return GeneralRadioGroup.prototype
  }
}

import { FormsFieldPreset, customTypes, FieldPreset  } from '../../../../../../constants/field-types'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../../../../../constants/component-types'
import { DEFAULT_PLACEHOLDER } from '../constants'
import { iconNames } from '../../icons/types'
import { IGeneralField } from '../../general-field'

export class GeneralNumber extends IGeneralField {
  public get customFields() {
    return [customTypes.NUMBER]
  }

  protected get icon() {
    return iconNames.number
  }

  public get collectionFieldType() {
    return FieldCollectionType.NUMBER
  }

  protected get componentType() {
    return COMPONENT_TYPES.TEXT_INPUT
  }

  protected get role() {
    return FIELDS.ROLE_FIELD_TEXT
  }

  protected get data(): any {
    return {
      textType: 'number',
      placeholder: this.translate(DEFAULT_PLACEHOLDER),
    }
  }

  protected get fieldType(): FieldPreset {
    return FormsFieldPreset.GENERAL_NUMBER
  }

  public get base() {
    return GeneralNumber.prototype
  }
}

import * as _ from 'lodash'
import { RegistrationFieldPreset } from '../../../../../../constants/field-types'
import { FIELDS } from '../../../../../../constants/roles'
import {
  GeneralSingleCheckbox,
} from './../../general-fields/definitions/general-single-checkbox'
import { FieldRenderConfigType } from '../../constants'
import { FormPlugin } from '../../../../../../constants/plugins'

export class CheckboxAgreeTerms extends GeneralSingleCheckbox {
  public get customFields() {
    return []
  }

  protected get renderConfig(): any {
    return {
      [FormPlugin.REGISTRATION_FORM]: {
        crmSync: FieldRenderConfigType.REMOVE,
        connectPanelRegistrationFooter: FieldRenderConfigType.ENABLED,
        required: FieldRenderConfigType.DISABLED,
        internalName: FieldRenderConfigType.DISABLED,
        checkedByDefault: FieldRenderConfigType.DISABLED,
      },
    }
  }

  public get allowOnlyOnce() {
    return true
  }

  public get disableCollectionSync() {
    return true
  }

  protected get fieldType() {
    return RegistrationFieldPreset.REGISTRATION_FORM_CHECKBOX_AGREE_TERMS
  }

  protected get role() {
    return FIELDS.ROLE_FIELD_REGISTRATION_FORM_CHECKBOX_AGREE_TERMS
  }

  protected get data() {
    return {
      label: this.translate(`preset.${_.camelCase(this.fieldType)}Label`),
    }
  }

  protected get props() {
    return {
      required: true,
    }
  }
}

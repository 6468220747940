import translations from '../../../../utils/translations'
import { customTypes, FieldPreset } from '../../../../constants/field-types'
import { iconNames } from './icons/types'
import { FieldCollectionType } from '../../../../constants/wixcode'
import { COMPONENT_TYPES } from '../../../../constants/component-types'
import { FieldProperties, FieldRenderConfig, PluginsFieldExtraData } from './field-types-data'
import * as _ from 'lodash'
import { CUSTOM_FIELD } from '../../../../constants/crm-types-tags'

interface IMetadata {
  fieldType: FieldPreset
  customFields: customTypes[]
  icon: iconNames
  ADI_titleTranslationKey: string
  allowOnlyOnce: boolean
  disableCollectionSync: boolean
  pluginsExtraData?: PluginsFieldExtraData
  renderConfig?: FieldRenderConfig
}

export abstract class IGeneralField {
  protected translate(key, options = {}): string {
    return translations.t(key, options)
  }

  // Accessor to base class
  public abstract base: IGeneralField

  // Field Props
  protected abstract fieldType: FieldPreset
  protected abstract icon: iconNames
  protected abstract componentType: COMPONENT_TYPES
  protected abstract role: string

  public get collectionFieldType(): FieldCollectionType {
    return null
  }

  public get customFields() {
    return [customTypes.TEXT]
  }

  public get crmType() {
    return CUSTOM_FIELD
  }

  public get crmTag() {
    return null
  }

  protected get data() {
    return {}
  }

  protected get props() {
    return {}
  }

  protected get layout() {
    return {}
  }

  protected get crmLabel() {
    return this.translate(`fieldTypes.${this.fieldType}`)
  }

  protected get ADI_titleTranslationKey() {
    return `adi.${this.fieldType}FieldEditPanel.title`
  }

  public get allowOnlyOnce() {
    return false
  }

  public get disableCollectionSync() {
    return false
  }

  protected get renderConfig(): Partial<FieldRenderConfig> | null {
    return null
  }

  protected get pluginsExtraData(): Partial<PluginsFieldExtraData> | null {
    return null
  }

  get metadata(): IMetadata {
    return {
      customFields: this.customFields,
      icon: this.icon,
      ADI_titleTranslationKey: this.ADI_titleTranslationKey,
      allowOnlyOnce: this.allowOnlyOnce,
      disableCollectionSync: this.disableCollectionSync,
      renderConfig: this.renderConfig,
      pluginsExtraData: this.pluginsExtraData,
      fieldType: this.fieldType,
    }
  }

  get properties(): FieldProperties {
    const data = _.merge({}, this.base.data, this.data)
    const props = _.merge({}, this.base.props, this.props)
    const layout = _.merge({}, this.base.layout, this.layout)

    const extraData = {
      ...(_.isEmpty(data) ? {} : { data }),
      ...(_.isEmpty(props) ? {} : { props }),
      ...(_.isEmpty(layout) ? {} : { layout }),
    }

    const connectionConfig: any = {
      ...(_.isEmpty(this.crmType) ? {} : { crmType: this.crmType }),
      ...(_.isEmpty(this.crmLabel) ? {} : { crmLabel: this.crmLabel }),
      ...(_.isEmpty(this.crmTag) ? {} : { crmTag: this.crmTag }),
    }

    return {
      ...(_.isEmpty(this.collectionFieldType)
        ? {}
        : { collectionFieldType: this.collectionFieldType }),
      componentType: this.componentType,
      extraData: {
        role: this.role,
        ...(_.isEmpty(connectionConfig) ? {} : { connectionConfig }),
        ...extraData,
      },
    }
  }
}

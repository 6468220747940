import { FIELDS } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../../../../../constants/component-types'
import { RegistrationFieldPreset } from '../../../../../../constants/field-types'
import { iconNames } from '../../icons/types'
import { FormPlugin } from '../../../../../../constants/plugins'
import { IGeneralField } from '../../general-field'

export class LinkToLogin extends IGeneralField {
  public get customFields() {
    return []
  }

  public get allowOnlyOnce() {
    return true
  }

  public get crmType() {
    return null
  }

  protected get crmLabel() {
    return null
  }

  public get disableCollectionSync() {
    return true
  }

  protected get icon() {
    return iconNames.link
  }

  protected get renderConfig() {
    return {
      [FormPlugin.REGISTRATION_FORM]: {
        isMandatory: true,
      },
    }
  }

  protected get fieldType() {
    return RegistrationFieldPreset.REGISTRATION_FORM_LINK_TO_LOGIN
  }

  protected get componentType() {
    return COMPONENT_TYPES.RICH_TEXT
  }

  protected get role() {
    return FIELDS.ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG
  }

  protected get data(): any {
    return {
      text: this.translate(`fieldTypes.${this.fieldType}.text`),
    }
  }

  protected get props() {
    return {
      required: true,
    }
  }

  public get base() {
    return LinkToLogin.prototype
  }
}

import { FormsFieldPreset, FieldPreset } from '../../../../../../constants/field-types'
import { GeneralText } from '../../general-fields/definitions/general-text'
import { CRM_TYPES, CRM_TAGS } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'

export class Address extends GeneralText {
  public get customFields() {
    return []
  }

  public get crmTag() {
    return CRM_TAGS.OTHER
  }

  protected get icon() {
    return iconNames.address
  }

  protected get fieldType(): FieldPreset {
    return FormsFieldPreset.ADDRESS
  }

  public get crmType() {
    return CRM_TYPES.ADDRESS
  }

  protected get data(): any {
    return {
      placeholder: this.translate(`fieldTypes.${this.fieldType}`),
    }
  }
}

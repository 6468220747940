import { FormsFieldPreset, customTypes, FieldPreset } from '../../../../../../constants/field-types'
import { DEFAULT_PLACEHOLDER } from '../constants'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../../../../../constants/component-types'
import { iconNames } from '../../icons/types'
import { IGeneralField } from '../../general-field'

export class GeneralDatePicker extends IGeneralField {
  public get customFields() {
    return [customTypes.DATE]
  }

  protected get icon() {
    return iconNames.date
  }

  public get collectionFieldType() {
    return FieldCollectionType.TEXT
  }

  protected get componentType() {
    return COMPONENT_TYPES.DATE_PICKER
  }

  protected get role() {
    return FIELDS.ROLE_FIELD_DATE
  }

  protected get data(): any {
    return {
      placeholder: this.translate(DEFAULT_PLACEHOLDER),
    }
  }

  protected get fieldType(): FieldPreset {
    return FormsFieldPreset.GENERAL_DATE_PICKER
  }

  public get base() {
    return GeneralDatePicker.prototype
  }
}
